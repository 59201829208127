import { authStorage } from '@/services'

export default {
  namespaced: true,

  state: {
    user: authStorage.getUser(),
    userId: '',
    token: authStorage.getAccessToken(),
    refreshToken: authStorage.getRefreshToken(),
    userProfile: null,
    requestReset: false,
    contactSubmitted: false,
    dataSentToApi: null,
    receiveNewsletter: false,
    passwordChanged: false,
    followEmailsUnsubscribed: false,
    notificationCount: 0,
    notifications: [],
    followedUserId: {},
    unfollowedUserId: {},
    viewableNotifications: 10
  },
  getters: {
    getName: state => {
      return state.name
    },
    getEmail: state => {
      return state.email
    },
    getProfession: state => {
      return state.profession
    },
    user: state => state.user,
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    userProfile: state => state.userProfile,
    avatar: state => state.user.avatarUrl,
    newsletter: state => state.receiveNewsletter,
    followEmails: state => state.followEmailsUnsubscribed,
    notificationCount: state => state.notificationCount,
    notifications: state => state.notifications.slice(0, state.viewableNotifications),
    following: state => state.user ? state.user.following : []
  },
  mutations: {
    SET_NAME (state, name) {
      state.name = name || ''
    },
    SET_USER (state, user) {
      authStorage.setUser({ ...user })
      state.user = authStorage.getUser()
    },
    SET_USER_ID (state, userId) {
      state.userId = userId
    },
    SET_TOKEN (state, token) {
      authStorage.setAccessToken(token)
      state.token = authStorage.getAccessToken()
    },
    SET_REFRESH (state, token) {
      authStorage.setRefreshToken(token)
      state.refreshToken = authStorage.getRefreshToken()
    },
    SET_LOGOUT (state) {
      authStorage.clear()
      state.user = null
      state.token = null
    },
    SET_USER_PROFILE (state, profile) {
      state.userProfile = profile
    },
    SET_PROFILE_USER (state, user) {
      state.userProfile.user = user
    },
    SET_AVATAR (state, avatar) {
      state.avatar = avatar
      state.user.avatarUrl = avatar
    },
    RESET_REQUEST (state, request) {
      state.requestReset = request
    },
    SET_CONTACT_FORM_SUBMITTED (state, contactSubmitted) {
      state.contactSubmitted = contactSubmitted
    },
    SET_DATA_SENT (state, userObject) {
      state.dataSentToApi = userObject
    },
    SET_NEWSLETTER_PREFERENCE (state, newsletter) {
      state.receiveNewsletter = newsletter
    },
    SET_PASSWORD_CHANGED (state) {
      state.passwordChanged = true
    },
    SET_UNSUBSCRIBED_FOLLOW (state, unsubscribed) {
      state.followEmailsUnsubscribed = unsubscribed
    },
    SET_NOTIFICATION_COUNT (state, count) {
      state.notificationCount = count
    },
    DECREMENT_NOTIFICATION_COUNT (state) {
      --state.notificationCount
    },
    SET_NOTIFICATIONS (state, notifications) {
      state.notifications = notifications
    },
    SET_FOLLOWED_USER (state, userId) {
      state.followedUserId = userId
    },
    SET_UNFOLLOWED_USER (state, userId) {
      state.unfollowedUserId = userId
    },
    SET_VIEWABLE_NOTIFICATIONS (state) {
      state.viewableNotifications += 10
    }
  },
  actions: {
    async registerUser ({ commit }, regObj) {
      const { id } = await this.$api.registerNewUser(regObj)
      commit('SET_USER_ID', id)
    },

    async loginUser ({ commit }, loginObj) {
      const user = await this.$api.loginWithEmailAndPassword(loginObj.email, loginObj.password)
      commit('SET_USER', user.user.data)
      commit('SET_TOKEN', user.token)
      commit('SET_REFRESH', user.refreshToken)
      return user.data
    },

    async editUser ({ commit }, userObj) {
      const user = await this.$api.updateUser(userObj)
      commit('SET_USER', user.data)
      commit('SET_PROFILE_USER', user.data)
    },

    async logoutUser ({ commit }) {
      commit('SET_LOGOUT')
    },

    async deleteUser ({ commit }) {
      await this.$api.deleteCurrentUser()
      commit('SET_LOGOUT')
      this.$storeRouter.push({ name: 'register' })
    },

    async getFollowers () {
      const res = await this.$api.getCurrentUserFollowers()
      return res
    },

    async followUser ({ commit, dispatch }, followObject) {
      commit('SET_FOLLOWED_USER', followObject.userId)
      await this.$api.addFollower(followObject)
      await dispatch('getUser')
    },

    async unfollowUser ({ commit, dispatch }, userId) {
      commit('SET_UNFOLLOWED_USER', userId)
      await this.$api.removeFollower(userId)
      await dispatch('getUser')
    },

    async getUser ({ commit }) {
      const currentToken = authStorage.getAccessToken()
      if (!currentToken) {
        return
      }
      const user = await this.$api.getCurrentUser()
      authStorage.setUser(user.data)
      commit('SET_USER', user.data)
      return user.data
    },

    async newsletterSubscribe ({ commit }, subObj) {
      const sub = await this.$api.subToNewsletter(subObj.email)
      return [sub, commit]
    },

    async getUserProfile ({ commit }, userId) {
      commit('SET_USER_PROFILE', null)
      const { data: profile } = await this.$api.getUserProfile(userId)

      profile.userVotes = profile.userVotes.flat(3)
      profile.userVotes = profile.userVotes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      commit('SET_USER_PROFILE', profile)

      return profile
    },

    async uploadUserAvatar ({ commit }, formData) {
      const { data: url } = await this.$api.uploadUserAvatar(formData)
      commit('SET_AVATAR', url.avatarUrl)
      return url.avatarUrl
    },

    async requestReset ({ commit }, email) {
      await this.$api.requestUserReset(email)
      commit('RESET_REQUEST', true)
    },

    async submitContactForm ({ commit }, formData) {
      await this.$api.submitContactForm(formData)
      commit('SET_CONTACT_FORM_SUBMITTED', true)
    },

    async setTempToken ({ commit }, payload) {
      commit('SET_TOKEN', payload)
    },

    async sendUserToAPI ({ commit }, userObject) {
      await this.$api.sendUserDataToApi(userObject)
      commit('SET_DATA_SENT', userObject)
    },

    async setNewsletterPreference ({ commit }, subObj) {
      const sub = await this.$api.subSocialLoginToNewsletter(subObj.newsletterPreference)
      commit('SET_NEWSLETTER_PREFERENCE', subObj.newsletterPreference)
      return [sub, commit]
    },

    async getUserById ({ commit }, userId) {
      const { data: user } = await this.$api.getUserById(userId)
      commit('SET_USER', user)
      return user.data
    },

    async sendUserPreferences ({ commit }, preferences) {
      await this.$api.sendNewUserPreferences(preferences)
      commit('SET_NEWSLETTER_PREFERENCE', preferences.newsletter)
    },

    async updateUserPassword ({ commit }, passwordObject) {
      await this.$api.updateUserPassword(passwordObject)
      commit('SET_PASSWORD_CHANGED')
    },

    async unsubscribeFollowEmails ({ commit }, unsubscribeObject) {
      const res = await this.$api.unsubscribeFollowEmails(unsubscribeObject)
      commit('SET_UNSUBSCRIBED_FOLLOW', res)
      return res
    },

    async getUserNotificationCount ({ commit }) {
      const { data: count } = await this.$api.getUserNotificationCount()
      commit('SET_NOTIFICATION_COUNT', count)
      return count
    },

    async updateUserNotificationCount ({ commit }, notification) {
      await this.$api.updateUserNotificationCount(notification)
      commit('DECREMENT_NOTIFICATION_COUNT')
    },

    async getUserNotifications ({ commit }) {
      const { data: notifications } = await this.$api.getUserNotifications()
      commit('SET_NOTIFICATIONS', notifications)
      return notifications
    },

    async updateViewableNotifications ({ commit }) {
      commit('SET_VIEWABLE_NOTIFICATIONS')
    }
  }
}
