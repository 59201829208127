import { createAxiosClient } from './client'
import {
  addAuthorizationHeaderInterceptor,
  captureServerResponseErrorInterceptor,
  refreshAndRetryResponseErrorInterceptor,
  expireSessionResponseErrorInterceptor
} from './interceptors'

const createWithSessionClient = ({ authStorage, refreshTokensHandler, errorService, authEventService }) =>
  (config) => {
    return createAxiosClient({
      ...config,
      requestInterceptors: [
        addAuthorizationHeaderInterceptor({ authStorage })
      ],
      responseErrorInterceptors: [
        captureServerResponseErrorInterceptor({ errorService }),
        // Order matters, as must retry before expiring session
        refreshAndRetryResponseErrorInterceptor({ refreshTokensHandler, authStorage }),
        expireSessionResponseErrorInterceptor({ authStorage, authEventService })
      ]
    }).client
  }

export {
  createWithSessionClient
}
