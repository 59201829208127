export default {
  namespaced: true,

  state: {
    testimonials: [
      {
        body: 'I enjoy the social work Magazine keeping up to date with new issues, legislation etc. It also informs my practice and the forums and topics are interesting too . It’s nice to be part of the social work community and network amongst colleagues',
        author: 'Thelma Jones'
      },
      {
        body: "It allows me to stay up today with current, practices and guidelines and it's good to hear about social workers dilemma's in practice and changes to take affect. I work as a children's therapist but hold my registration as it is my foundation and important to my practice and learning.",
        author: 'Val Cortese'
      },
      {
        body: 'I like to keep up to date with news, views and up to date with what’s going on in the world of Social Work. This can be a very lonely place',
        author: 'Levi Reed'
      },
      {
        body: "I love Social Work News because...its always up to date, informative and supportive of social workers! It's a good read and I look forward to reading it. Thank you!",
        author: 'Terri Verier'
      },
      {
        body: 'Social work news keeps me updated with the current situation hence it enables maximisation of resources.',
        author: 'Noel Madzima'
      },
      {
        body: 'I love Social Work News because...it brings me up to day with theory and practice',
        author: 'Denice Blakebrough'
      },
      {
        body: 'Great magazine that provides interesting and up-to-date information',
        author: "Social Worker, Children's Services"
      },
      {
        body: 'Very informative and diversified. It’s interesting to hear the experience and views from other frontline workers from various fields',
        author: 'Social Worker, Adult services'
      },
      {
        body: 'The magazine is easy to read and covers the diverse areas of social work',
        author: 'Social Worker, Adult Services'
      }
    ]
  },
  getters: {
    getTestimonials: state => {
      return state.testimonials
    }
  }
}
