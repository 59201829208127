
const api = (client) => ({

  // Duped from api store, but different reason to change
  getArticleBySlug (slug) {
    return client.get(`/articles/${slug}`)
  },

  getAllCurateSubmissions () {
    return client.get('/curations/subs')
  },

  getCurrentUser: async () => {
    const res = await client.get('/curations/users')
    return res.data
  },

  getAllUsers () {
    return client.get('/curations/users/all')
  },

  setNewAOTM (aotmObject) {
    return client.post('/curations/aotm', aotmObject)
  },

  //  Submissions API calls

  uploadCurateImage (formData) {
    return client.post('/submissions/curate/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  upsertSubmission (submission) {
    return client.post('/submissions/upsert', { ...submission })
  },

  getAllSubmissions () {
    return client.get('/curations/subs')
  },

  getAllSurveys () {
    return client.get('/survey/list')
  },

  publishSurvey (surveyId) {
    return client.put('/survey/update', { id: surveyId })
  },

  newSurvey (surveyObj) {
    return client.post('/survey/create', surveyObj)
  },

  updateSurveyImage (formData) {
    return client.post('/survey/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getSurveyResults (surveyId) {
    return client.post('/survey/results', { id: surveyId })
  },

  deleteASubmission (subId) {
    return client.delete(`/submissions/${subId}`)
  },

  editASubmission ({ subId, contentHtml, deltas, usePseudonym }) {
    return client.put(`/submissions/${subId}`, {
      contentHtml,
      deltas,
      usePseudonym
    })
  },

  createSubmission ({ contentHtml, deltas, usePseudonym }) {
    return client.post('/submissions', { contentHtml, deltas, usePseudonym })
  },

  getASubmission (subId) {
    return client.get(`/curations/${subId}`)
  },

  addTagsToSubmission (submissionId, tags) {
    return client.post(`/submissions/${submissionId}/tags`, { tags })
  },

  deleteTagsFromSubmission (submissionId, tags) {
    return client.delete(`/submissions/${submissionId}/tags/${tags}`)
  },

  publishArticle (submissionId) {
    return client.post(`/curations/approve/${submissionId}`)
  },

  rejectArticle (subObj) {
    const id = subObj.id
    delete subObj.id
    const subArray = subObj.data
    return client.post(`curations/reject/${id}`, { subArray })
  },

  getRejectionReasons () {
    return client.get('/curations/rejections/reasons')
  },

  deleteSubmissions (submissionsArray) {
    return client.delete('curations/delete', { data: { submissionsArray } })
  },

  async globalSearch (query, type = null) {
    const { data } = await client.get('/search', { params: { q: query, type } })
    return data
  },

  getArticleTags (articleId) {
    return client.get(`/tags/${articleId}`)
  },

  getPublishedArticles (offset, limit) {
    return client.get('/curations/get/articles', { params: { offset, limit } })
  },

  deleteArticle (articleId) {
    return client.delete(`/curations/articles/${articleId}`)
  },

  addTagsToArticle (articleId, tags) {
    return client.post(`/curations/articles/${articleId}/tags`, { tags })
  },

  deleteTagsFromArticle (articleId, tags) {
    return client.delete(`/articles/${articleId}/tags/${tags}`)
  },

  updateCurationArticleImage (formData) {
    return client.post('/articles/curate/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  archiveAnArticle (publishObj) {
    return client.put(`/curations/articles/${publishObj.id}`, { publish: publishObj.publish })
  },

  upsertArticle (article) {
    return client.post('/curations/article/upsert', { ...article })
  },

  uploadAnImage (formData) {
    return client.post('/curations/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
})

export {
  api
}
