<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  metaInfo () {
    return {
      titleTemplate: `%s | ${this.$config.env.VUE_APP_NAME}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$config.app.meta.GENERIC_DESCRIPTION
        },
        // Due to lack of SSR, OpenGraph content will be the same on every page in-app
        // and overwritten by Lambda on article pages
        {
          vmid: 'og:description',
          name: 'og:description',
          content: this.$config.app.meta.GENERIC_DESCRIPTION
        },
        {
          vmid: 'og:title',
          name: 'og:title',
          content: this.$config.env.VUE_APP_NAME
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content:
            `${this.$config.env.VUE_APP_CLIENT_URL}/img/${this.$config.env.VUE_APP_CONFIG_KEY}/og-image.png`
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary'
        },
        {
          vmid: 'twitter:site',
          name: 'twitter:site',
          content: `@${this.$config.app.social.TWITTER_USERNAME}`
        },
        {
          vmid: 'twitter:domain',
          name: 'twitter:domain',
          content: this.$config.env.VUE_APP_HOSTNAME
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.theme-swn {
  --accent-color: #{$blue};
}

.theme-epn {
  --accent-color: #{$purple};
}
</style>
