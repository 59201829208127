
// Utilities which don't require a sessioned client
const utilityAPI = (client) => {
  return {
    // This should require login really and setting preferences on the user
    // As any old schmo can call this and unsubscribe people from emails
    unsubscribeFollowEmails: async ({ userId, authorId }) => {
      try {
        const response = await client.put('/users/unsubscribe/follow-emails', { userId, authorId })
        // TODO: Cleanup in API as shouldn't return error object as data!
        return response.data === true
      } catch {
        return false
      }
    }
  }
}

export { utilityAPI }
