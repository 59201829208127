
import { createBasicClient, createWithSingleSessionClient } from './client'
import * as CurateApi from './curate-apis'

const API_URL = process.env.VUE_APP_API_URL

const createCurateApiService = ({ errorService, authStorage, authEventService }) => {
  const unauthenticatedClient = createBasicClient({ errorService })({
    baseURL: API_URL
  })

  const authClient = CurateApi.authAPI(unauthenticatedClient)

  const withSessionClient = createWithSingleSessionClient({
    errorService,
    authStorage,
    authEventService
  })({
    baseURL: API_URL
  })

  const apiClient = CurateApi.api(withSessionClient)

  return {
    ...authClient,
    ...apiClient,

    // TODO: Login API should really return the user object to prevent this
    // coupling between the authed client and unauthenticated client
    login: async ({ email, password }) => {
      const tokens = await authClient.login({ email, password })
      authStorage.setTokens(tokens)
      const user = await apiClient.getCurrentUser()
      authStorage.setUser(user)
      return {
        ...tokens,
        user
      }
    }
  }
}

export {
  createCurateApiService
}
