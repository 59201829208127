import Axios from 'axios'

const addRequestInterceptor = (requestInterceptor, context) =>
  context.axios.interceptors.request.use(
    (config) => requestInterceptor(config, context),
    (error) => Promise.reject(error)
  )

const addResponseErrorInterceptor = (errorInterceptor, context) =>
  context.axios.interceptors.response.use(
    (response) => response,
    (error) => errorInterceptor(error, context)
  )

// Convenience wrapper around Axios.create for easier interceptor adding
const createAxiosClient = ({
  responseErrorInterceptors: responseErrorInterceptorsInput,
  requestInterceptors: requestInterceptorsInput,
  ...config
}) => {
  const axios = Axios.create(config)

  // Reverse as interceptors are executed in reverse order
  requestInterceptorsInput = (requestInterceptorsInput || []).reverse()
  responseErrorInterceptorsInput = responseErrorInterceptorsInput || []

  // Pass context to interceptors (e.g. same axios instance for retries)
  const context = {
    axios
  }

  const requestInterceptors = requestInterceptorsInput.map(
    interceptorHandler => addRequestInterceptor(interceptorHandler, context)
  )

  const responseErrorInterceptors = responseErrorInterceptorsInput.map(
    interceptorHandler => addResponseErrorInterceptor(interceptorHandler, context)
  )

  return {
    client: axios,
    requestInterceptors,
    responseErrorInterceptors
  }
}

export {
  createAxiosClient
}
