export default {
  namespaced: true,

  state: {
    articles: [],
    featured: [],
    recent: [],
    article: {},
    error: null,
    lastVoted: null,
    similarArticles: [],
    moreGreatReads: [],
    moreLikeThis: [],
    newsArticles: [],
    relatedTopics: [],
    latestTag: null
  },
  getters: {
    getArticleResponses: state => {
      return state.response
    },
    getMoreArticleResponses: state => {
      return state.responseTwo
    },
    getArticles: state => {
      return state.articles
    },
    getFeatured: state => {
      return state.featured
    },
    getRecent: state => {
      return state.recent
    },
    getArticle: state => {
      return state.article
    },
    getMoreLikeThis: state => {
      return state.moreLikeThis
    },
    getMoreGreatReads: state => {
      return state.moreGreatReads
    },
    getNewsArticles: state => {
      return state.newsArticles
    },
    relatedTopics: state => {
      return state.relatedTopics
    },
    error: state => state.error
  },
  mutations: {
    SET_ARTICLES (state, articles) {
      state.articles = articles
    },
    SET_TAG_ARTICLES (state, articles) {
      for (const article of articles) {
        state.articles.push(article)
      }
    },
    APPEND_ARTICLES (state, articles) {
      state.articles = [...state.articles, ...articles]
    },
    SET_FEATURED (state, articles) {
      state.featured = articles
    },
    SET_RECENT (state, articles) {
      state.recent = articles
    },
    SET_ARTICLE (state, article) {
      state.article = article
    },
    SET_ERROR (state, error) {
      state.error = error
    },
    LAST_VOTED_ARTICLE (state, vote) {
      state.lastVoted = vote
    },
    SET_SIMILAR_ARTICLES (state, similarArticles) {
      state.similarArticles = similarArticles
      state.moreLikeThis = similarArticles.slice(0, 2)
      state.moreGreatReads = similarArticles.slice(2, similarArticles.length)
    },
    SET_NEWS_ARTICLES (state, articles) {
      state.newsArticles = articles
    },
    SET_RELATED_TOPICS (state, relatedTopics) {
      state.relatedTopics = relatedTopics
    },
    SET_LATEST_TAG (state, tag) {
      state.latestTag = tag
    },
    RESET_TAG (state) {
      state.articles = []
      state.relatedTopics = []
      state.latestTag = null
    }
  },
  actions: {
    async createArticleDraft (articleObj) {
      const article = await this.$api.createArticle(articleObj.contentHtml, articleObj.deltas, articleObj.typeId)
      return article.data
    },

    async getFeatured ({ commit }) {
      const res = await this.$api.getFeaturedArticles()
      const articles = (res && res.data) ? res.data : []
      commit('SET_FEATURED', articles)
      return articles
    },

    async getRecent ({ commit }, limit) {
      const res = await this.$api.getRecentArticles(limit)
      const articles = (res && res.data) ? res.data : []
      commit('SET_RECENT', articles)
      return articles
    },

    async getArticles ({ commit }) {
      const res = await this.$api.getAllArticles()
      commit('SET_ARTICLES', res.data)
      return res.data
    },

    async searchArticles ({ commit }, { ...args }) {
      if (args.offset === 0) commit('SET_ARTICLES', [])
      const res = await this.$api.searchArticles(args.limit, args.offset, args.order, args.search)
      const { hits } = res.data
      const mutation = (args.offset === 0) ? 'SET_ARTICLES' : 'APPEND_ARTICLES'
      commit(mutation, hits)
      return res.data
    },

    async deleteAnArticle (articleId) {
      await this.$api.deleteArticle(articleId)
    },

    async editAnArticle (articleObj) {
      const article = await this.$api.editArticle(articleObj.articleId, articleObj.contentHtml, articleObj.deltas)
      return article.data
    },

    async getAnArticle ({ commit }, articleSlug) {
      const article = await this.$api.getArticle(articleSlug)
      commit('SET_ARTICLE', article.data)
      return article.data
    },

    async addTagsArticle (articleObj) {
      const article = await this.$api.addTagsToArticle(articleObj.articleId, articleObj.tags)
      return article.data
    },

    async removeTagsFromArticle (articleObj) {
      const article = await this.$api.deleteTagsFromArticle(articleObj.articleId, articleObj.tags)
      return article.data
    },

    async articleVote ({ commit }, articleObj) {
      const { data: vote } = await this.$api.voteOnArticle(articleObj.articleId, articleObj.voteDelta)
      commit('LAST_VOTED_ARTICLE', vote)
      return vote
    },

    async fetchSimilarArticles ({ commit }, articleId) {
      const res = await this.$api.getSimilarArticles(articleId)
      if (res?.data?.articles) {
        commit('SET_SIMILAR_ARTICLES', res.data.articles)
        return res.data.articles
      } else {
        // TODO: Legacy handler, remove me when API is updated
        const articles = (res && res.data) ? res.data : []
        commit('SET_SIMILAR_ARTICLES', articles)
      }
    },

    async getArticlesFromTag ({ commit, state }, { ...args }) {
      if (args.tag !== state.latestTag) {
        await commit('RESET_TAG')
      }
      commit('SET_LATEST_TAG', args.tag)
      const res = await this.$api.getArticlesFromTag(args.tag, args.offset, args.limit, args.excludeTags)
      commit('SET_TAG_ARTICLES', res.data.articles)
      commit('SET_RELATED_TOPICS', res.data.relatedTags)
      return res.data
    },

    async fetchNewsArticles ({ commit }) {
      const { data: articles } = await this.$api.fetchNewsArticles()
      commit('SET_NEWS_ARTICLES', articles)
    }
  }
}
