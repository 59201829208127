import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const init = (Vue, router, {
  tracesSampleRate,
  tracing: {
    tracePropagationTargets
  },
  environment,
  dsn,
  ignoreErrors
}) => {
  Sentry.init({
    Vue,
    dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets
      })
    ],
    tracesSampleRate,
    environment,
    ignoreErrors
  })
}

const captureException = (error, context) => {
  Sentry.captureException(error, context)
}

export {
  init,
  captureException
}
