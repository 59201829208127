
import Vuex from 'vuex'
import sharedMutations from 'vuex-shared-mutations'
import Articles from './modules/articles'

import Issues from './modules/issues'
import User from './modules/user'
import Tags from './modules/tags'
import Submissions from './modules/submissions'
import Surveys from './modules/surveys'
import Testimonials from './modules/testimonials'
import Writers from './modules/writers'
import UnsavedChanges from './modules/unsaved-changes'

const createStore = (Vue, { curateStore }) => {
  Vue.use(Vuex)

  return new Vuex.Store(
    {
      modules: {
        // nested lowercase for path syntax (see docs)
        curate: curateStore,
        // TODO: use `magazine` path for magazine store as with curate
        Articles,
        Issues,
        User,
        Tags,
        Submissions,
        Surveys,
        Testimonials,
        Writers,
        UnsavedChanges
      },
      plugins: [sharedMutations({ predicate: ['User/SET_TOKEN', 'User/SET_USER'] })]
    })
}

export { createStore }
