
const captureServerResponseErrorInterceptor = ({ errorService }) => async (error) => {
  const { response } = error
  if (!response) throw error

  const { status } = response

  if (status >= 500 && status < 600) {
    errorService.captureException(error, {
      tags: {
        type: 'server-response-error'
      }
    })
  }

  throw error
}

export {
  captureServerResponseErrorInterceptor
}
