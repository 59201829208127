
const removeAuthorizationHeader = (headers) => {
  const { Authorization, ...rest } = headers
  return rest
}

const addAuthorizationHeaderInterceptor = ({ authStorage }) => (config) => {
  const accessToken = authStorage.getAccessToken()

  if (!accessToken) {
    return {
      ...config,
      headers: removeAuthorizationHeader(config.headers)
    }
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`
    }
  }
}

export { addAuthorizationHeaderInterceptor }
