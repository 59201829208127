const createCurateRoutes = ({ getCurrentUserHandler }) => {
  const protectCurateRoute = (_to, _from, next) => {
    const user = getCurrentUserHandler()

    if (!user) {
      next({
        name: 'curate-login'
      })
    } else {
      next()
    }
  }

  return [
    {
      path: '',
      name: 'curate-login',
      component: () => import('@/views/curate/Login.vue'),
      beforeEnter: (_to, _from, next) => {
        const user = getCurrentUserHandler()

        if (user) {
          next({
            name: 'curate-pending'
          })
        } else {
          next()
        }
      }
    },
    {
      path: 'pending',
      name: 'curate-pending',
      component: () => import('@/views/curate/Pending.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'articles',
      name: 'curate-articles',
      component: () => import('@/views/curate/Articles.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'article/:slug',
      name: 'curate-article-view',
      props: route => ({ query: route.params.article }),
      component: () => import('@/views/curate/Article.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'edit-article/:slug',
      name: 'curate-edit-article',
      props: route => ({ query: route.params.article }),
      component: () => import('@/views/curate/EditArticle.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'submission/:id',
      name: 'curate-submission-view',
      props: route => ({ query: route.params.submission }),
      component: () => import('@/views/curate/Curation.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'submissions',
      name: 'curate-submissions',
      component: () => import('@/views/curate/Submissions.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'aotm',
      name: 'curate-aotm',
      component: () => import('@/views/curate/AOTM.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'surveys',
      name: 'curate-surveys',
      component: () => import('@/views/curate/Surveys.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: 'surveys/new',
      name: 'curate-survey-create',
      component: () => import('@/views/curate/NewSurvey.vue'),
      beforeEnter: protectCurateRoute
    },
    {
      path: '*',
      name: 'curate-redirect',
      component: () => import('@/views/PageNotFound.vue'),
      props: {
        redirectPath: '/curate'
      }
    }
  ]
}

export {
  createCurateRoutes
}
