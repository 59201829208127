import { z } from 'zod'

const AppEnum = z.enum(['swn', 'epn'])

const EnvSchema = z.object({
  NODE_ENV: z.enum(['development', 'production', 'test']).default('development'),
  VUE_APP_NAME: z.string(),
  VUE_APP_CONFIG_KEY: AppEnum,
  VUE_APP_HOSTNAME: z.string(),
  VUE_APP_API_URL: z.string(),
  VUE_APP_CLIENT_URL: z.string(),
  VUE_APP_STRIPE_KEY: z.string(),
  VUE_APP_FB_APP_ID: z.string(),
  VUE_APP_GTAG_ID: z.string(),
  VUE_APP_GTM_ID: z.string(),
  VUE_APP_HUBSPOT_ANALYTICS_ID: z.string()
})

const env = EnvSchema.parse(process.env)

export { env, AppEnum }
