export default {
  namespaced: true,

  state: {
    submissions: [],
    requests: [],
    current: { id: null },
    error: null,
    lastImageUpload: null,
    lastDeleted: null
  },
  getters: {
    getAllDraftSubmissions: state => {
      return state.submissions.filter(submission => submission.draft === true && submission.published === false)
    },
    submissions: state => {
      const submissions = state.submissions = state.submissions.filter(submission => {
        if (submission.draft === false) {
          return submission
        }
        if (submission.Statuses.length > 0) {
          return submission
        }
        if (submission.RejectionReasons.length > 0) {
          return submission
        }
      })
      return submissions
    },
    current: state => state.current,
    error: state => state.error
  },
  mutations: {
    SET_SUBMISSIONS (state, submissions) {
      state.submissions = submissions || []
    },
    SET_CURRENT (state, current = {}) {
      state.current = current
    },
    SET_REQUESTED (state, request) {
      state.requests.push(request)
    },
    SET_ERROR (state, error) {
      state.error = error
    },
    LAST_IMAGE_UPLOAD (state, url) {
      state.lastImageUpload = url
    },
    SET_LAST_DELETED (state, deleted) {
      state.lastDeleted = deleted
    },
    CLEAR_CURRENT (state) {
      state.current = { id: null }
    }
  },
  actions: {
    async createNewSubmission ({ commit }, subObj) {
      subObj = {
        ...subObj,
        typeId: 1
      }
      commit('SET_CURRENT', {})
      await this.$api.createSubmission({ ...subObj })
      return commit
    },
    async createOrUpdateDraft ({ commit }, submission) {
      try {
        const { data: submitted } = await this.$api.upsertSubmission({ ...submission })
        commit('SET_CURRENT', submitted)
        return submitted
      } catch (err) {
        commit('SET_ERROR', err)
        throw err
      }
    },
    async sendPublishRequest ({ commit }, id) {
      try {
        const { data: request } = await this.$api.sendPublishRequest(id)
        commit('SET_CURRENT', {})
        commit('SET_REQUESTED', request)
        return request
      } catch (err) {
        commit('SET_ERROR', err)
        throw err
      }
    },
    async getAllSubs ({ commit }) {
      const subs = await this.$api.getAllSubmissions()
      commit('SET_SUBMISSIONS', subs.data)
      return subs.data
    },

    async getAllDrafts ({ commit }) {
      const subs = await this.$api.getAllDrafts()
      commit('SET_SUBMISSIONS', subs.data)
      return subs.data
    },

    async deleteSub ({ commit }, subId) {
      const { data: deleted } = await this.$api.deleteASubmission(subId)
      commit('SET_LAST_DELETED', deleted)
      commit('CLEAR_CURRENT')
      return deleted
    },

    async editSub ({ commit }, subObj) {
      await this.$api.editASubmission({ ...subObj })
      return commit
    },

    async getSubById ({ commit }, subObj) {
      const sub = await this.$api.getASubmission(subObj.id)
      commit('SET_CURRENT', sub.data)
      return sub.data
    },

    async addTagsSubmission ({ commit }, subObj) {
      await this.$api.addTagsToSubmission(subObj.submissionId, subObj.tags)
      return commit
    },

    async removeTagsFromSubmission ({ commit }, subObj) {
      const sub = await this.$api.deleteTagsFromSubmission(subObj.submissionId, subObj.tags)
      commit('SET_CURRENT', sub.data)
      return commit
    },

    async postArticleImage ({ commit }, formData) {
      const { data: url } = await this.$api.uploadArticleImage(formData)
      commit('LAST_IMAGE_UPLOAD', url.imageUrl)
      return url
    },

    async emptyCurrentObject ({ commit }) {
      commit('CLEAR_CURRENT')
    }

  }
}
