const error = {
  DSN: 'https://b7d28f2c81e84141826cb61943e9ea99@o4504847854272512.ingest.sentry.io/4504894341644288',
  TRACE_SAMPLE_RATE: process.env.NODE_ENV === 'production' ? 0.05 : 1.0,
  // TODO: Add Ed Psych domain here
  TRACE_PROPAGATION_TARGETS: ['localhost', 'mysocialworknews.com', /^\//],
  IGNORE_ERRORS: [
    // Unreplicable Facebook SDK issue
    'init not called with valid version'
  ]
}

export {
  error
}
