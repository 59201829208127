import { z } from 'zod'

const AxiosResponseSchema = (DataSchema) => z.object({
  data: DataSchema
}).transform((axiosResponse) => axiosResponse.data)

const TokenResponseSchema = z.object({
  id: z.string(),
  token: z.string()
})

const authAPI = (client) => ({
  login: async ({ email, password }) => {
    const response = await client
      .post('/curation/login', { email, password })

    const maybeData = AxiosResponseSchema(TokenResponseSchema).safeParse(response)
    if (!maybeData.success) throw new Error('We couldn\'t find a user with that email/password combo.')
    return maybeData.data
  }
})

export { authAPI }
