import babelPolyfill from 'babel-polyfill'
import Vue from 'vue'
import * as config from './config'
import {
  errorService,
  createApiService,
  createCurateApiService,
  authStorage,
  curateAuthStorage,
  authEventService,
  curateAuthEventService
} from '@/services'
import App from './App.vue'

import { createStore } from './store'
import VueMq from 'vue-mq'
import VueMeta from 'vue-meta'

import { format, parseISO } from 'date-fns'
import Joi from 'joi'

import VueCroppie from 'vue-croppie'
// Import styles for croppie - excluded from .dependency-cruiser.js
import 'croppie/croppie.css'

import InfiniteLoading from 'vue-infinite-loading'
import VueTippy, { TippyComponent } from 'vue-tippy'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { createRouter } from './router'
import { createCurateStore } from './store/modules/curate'
import { createMainRoutes } from './router/main'
import { createCurateRoutes } from './router/curate'

Vue.use(babelPolyfill)
Vue.use(VueMeta)

Vue.use(InfiniteLoading)
Vue.use(VueCroppie)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 500,
    tablet: 900,
    larger: Infinity
  }
})
Vue.use(VueTippy, {
  directive: 'tippy',
  arrow: true
})
Vue.use(Toast, {
  hideProgressBar: true,
  toastClassName: 'alert alert--toast',
  icon: false,
  transition: 'Vue-Toastification__fade',
  transitionDuration: 200,
  position: 'bottom-right'
})

Vue.component('Tippy', TippyComponent)

Vue.filter('formatISODate', val => {
  return format(parseISO(val), 'PPP')
})

Vue.prototype.Joi = Joi
Vue.config.productionTip = false

const api = createApiService({
  errorService,
  authStorage,
  authEventService
})

Vue.prototype.$api = api
Vue.prototype.$config = config

const curateApi = createCurateApiService({
  errorService,
  authStorage: curateAuthStorage,
  authEventService: curateAuthEventService
})

const store = createStore(Vue, {
  curateStore: createCurateStore({
    authStorage: curateAuthStorage,
    api: curateApi
  })
  // TODO: Split magazine and hook up store here
})

// TODO Bad mutations / this. Pass in above in createStore
store.$api = api

const router = createRouter(Vue, {
  mainRoutes: createMainRoutes({
    getCurrentUserHandler: () => store.dispatch('User/getUser')
  }),
  curateRoutes: createCurateRoutes({
    getCurrentUserHandler: () => curateAuthStorage.getAccessToken()
  })
})

store.$storeRouter = router

errorService.init(Vue, router, {
  dsn: config.error.DSN,
  environment: config.env.NODE_ENV,
  tracing: {
    tracePropagationTargets: config.error.TRACE_PROPAGATION_TARGETS
  },
  tracesSampleRate: config.error.TRACE_SAMPLE_RATE,
  ignoreErrors: config.error.IGNORE_ERRORS
})

Vue.prototype.$errorService = errorService

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
