
// TODO: Add validation / parsing

const userAPI = (client) => ({
  updateUser ({ avatarUrl, avatar, ...user }) {
    return client.put('/users', user)
  },

  uploadUserAvatar (formData) {
    return client.post('/users/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getUserById (userId) {
    return client.get(`/users/get/${userId}`)
  },

  getCurrentUser () {
    return client.get('/users')
  },

  deleteCurrentUser () {
    return client.delete('/users')
  },

  getCurrentUserFollowers () {
    return client.get('/users/followers')
  },

  addFollower (followObject) {
    return client.post('/users/follow', followObject)
  },

  removeFollower (userId) {
    return client.delete('/users/follower', { data: { userId } })
  },

  getUserProfile (userId) {
    return client.get(`/users/${userId}`)
  },

  sendUserDataToApi (userObject) {
    return client.post('/community-preauth', userObject)
  },

  sendNewUserPreferences (preferences) {
    return client.put('/users/signupprefs', { preferences })
  },

  updateUserPassword (passwords) {
    return client.put('/users/update/password', { passwords })
  },

  getUserNotificationCount () {
    return client.get('/users/notifications/count')
  },

  updateUserNotificationCount (readNotification) {
    return client.put('/users/notifications', { readNotification })
  },

  getUserNotifications () {
    return client.get('/users/notifications')
  },

  // TODO: Why is this authenticated / in userAPI?
  submitContactForm (formData) {
    return client.post('/users/contact', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

})

const submissionsAPI = (client) => ({
  uploadArticleImage (formData) {
    return client.post('/submissions/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  upsertSubmission (submission) {
    return client.post('/submissions/upsert', { ...submission })
  },

  sendPublishRequest (submissionId) {
    return client.post(`/submissions/publish/${submissionId}`)
  },

  getAllSubmissions () {
    return client.get('/submissions')
  },

  getAllDrafts () {
    return client.get('/submissions/drafts')
  },

  deleteASubmission (subId) {
    return client.delete(`/submissions/${subId}`)
  },

  editASubmission ({ subId, contentHtml, deltas, usePseudonym }) {
    return client.put(`/submissions/${subId}`, { contentHtml, deltas, usePseudonym })
  },

  createSubmission ({ contentHtml, deltas, usePseudonym }) {
    return client.post('/submissions', { contentHtml, deltas, usePseudonym })
  },

  getASubmission (subId) {
    return client.get(`/submissions/${subId}`)
  },

  addTagsToSubmission (submissionId, tags) {
    return client.post(`/submissions/${submissionId}/tags`, { tags })
  },

  deleteTagsFromSubmission (submissionId, tags) {
    return client.delete(`/submissions/${submissionId}/tags/${tags}`)
  }

})

const surveyAPI = (client) => ({
  getSurvey () {
    return client.get('/survey/latest')
  },
  sendSurveyVote (voteObj) {
    return client.post('/survey/vote', voteObj)
  }
})

const issuesAPI = (client) => ({
  getLatestIssue () {
    return client.get('/issues/latest')
  }
})

const tagsAPI = (client) => ({
  createTag (name) {
    return client.post('/tags', { name })
  },
  getAllTags () {
    return client.get('/tags')
  }
})

const articlesAPI = (client) => ({

  fetchWriters () {
    return client.get('/users/writers')
  },

  getArticleTags (articleId) {
    return client.get(`/tags/${articleId}`)
  },

  getParentTags () {
    return client.get('/tags/parents')
  },

  createArticle (contentHtml, deltas, typeId, usePseudonym) {
    return client.post('/articles', { contentHtml, deltas, typeId, usePseudonym })
  },

  getFeaturedArticles () {
    return client.get('/articles/featured')
  },

  getRecentArticles (limit) {
    return client.get(`/articles/recent/${limit}`)
  },

  getAllArticles () {
    return client.get('/articles')
  },

  getArticlesFromTag (tag, offset, limit, excludeTags) {
    return client.get('/articles/tag', { params: { tag, offset, limit, excludeTags } })
  },

  getArticlesByAuthorId (authorId, offset, limit) {
    return client.get('/articles/author', { params: { authorId, offset, limit } })
  },

  searchArticles (limit, offset, order, search) {
    return client.get('/search/articles', { params: { limit, offset, order, search } })
  },

  deleteArticle (articleId) {
    return client.delete(`/articles/${articleId}`)
  },

  editArticle (articleId, contentHtml, deltas) {
    return client.put(`/articles/${articleId}`, { contentHtml, deltas })
  },

  getArticle (articleSlug) {
    return client.get(`/articles/${articleSlug}`)
  },

  addTagsToArticle (articleId, tags) {
    return client.post(`/articles/${articleId}/tags`, { tags })
  },

  deleteTagsFromArticle (articleId, tags) {
    return client.delete(`/articles/${articleId}/tags`, { tags })
  },

  voteOnArticle (articleId, voteDelta) {
    return client.post(`/articles/${articleId}/vote`, { voteDelta })
  },

  async globalSearch (query, type = null) {
    const { data } = await client.get('/search', { params: { q: query, type } })
    return data
  },

  async getSimilarArticles (articleId) {
    return await client.get(`/articles/${articleId}/similar`)
  },

  async fetchNewsArticles () {
    return client.get('/articles/news')
  }
})

const newsletterAPI = (client) => ({
  subSocialLoginToNewsletter (newsletterPreference) {
    return client.post('/users/social/subscribe', { newsletterPreference })
  },

  subToNewsletter (email) {
    return client.post('/users/subscribe', { email })
  }
})

const subscriptionAPI = (client) => ({

  onceOffPayment () {
    return client.post('/subscription/payment')
  },

  subscriptionPayment () {
    return client.post('/subscription/subscriptionpayment')
  }
})

// TODO: Maybe should export all as one, but this is how it was before
const api = (client) => ({
  ...userAPI(client),
  ...submissionsAPI(client),
  ...surveyAPI(client),
  ...issuesAPI(client),
  ...tagsAPI(client),
  ...articlesAPI(client),
  ...newsletterAPI(client),
  ...subscriptionAPI(client)
})

export { api }
