export default {
  namespaced: true,

  state: {
    writers: []
  },
  getters: {
    getWriters: state => {
      return state.writers
    }
  },
  mutations: {
    SET_WRITERS (state, writers) {
      state.writers = writers || []
    },
    SET_FOLLOWED (state, writer) {
      const index = state.writers.findIndex(w => w.id === writer.id)
      const newWriter = writer
      newWriter.followed = true
      state.writers.splice(index, 1, writer)
      state.writers.push(newWriter)
    }
  },
  actions: {
    async fetchWriters ({ commit }) {
      const { data: writers } = await this.$api.fetchWriters()
      commit('SET_WRITERS', writers)
    },
    markFollowed ({ commit }, writer) {
      commit('SET_FOLLOWED', writer)
    }
  }
}
