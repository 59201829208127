
const createCuratorStore = ({ authStorage, api }) => ({
  namespaced: true,

  state: {
    tempLogin: {
      email: 'test@example.com',
      password: 'Passw0rd$'
    },
    user: authStorage.getUser(),
    token: authStorage.getAccessToken(),
    users: [],
    newAotm: {},
    currentAotm: {}
  },
  getters: {
    getTempLogin: state => {
      return state.tempLogin
    },
    getAvailableUsers: state => {
      return state.users.users
    },
    getCurrentAOTM: state => {
      return state.users.aotm
    },
    user: state => state.user,
    token: state => state.token
  },
  mutations: {
    // Not sure if these are the best approaches...
    // As tokens are set in the auth api and prob shouldn't
    // be used here :shrug
    SET_USER (state, user) {
      authStorage.setUser({ ...user })
      state.user = authStorage.getUser()
    },
    SET_TOKEN (state, token) {
      authStorage.setAccessToken(token)
      state.token = authStorage.getAccessToken()
    },
    SET_LOGOUT (state) {
      authStorage.clear()
      state.user = null
      state.token = null
    },
    SET_ALL_USERS (state, users) {
      state.users = users
    },
    SET_NEW_AOTM (state, user) {
      state.newAotm = user
    }
  },
  actions: {
    async loginUser ({ commit }, loginObj) {
      const user = await api.login(loginObj)
      commit('SET_USER', user.user)
      commit('SET_TOKEN', user.token)
    },

    async logoutUser ({ commit }) {
      commit('SET_LOGOUT')
    },

    async getAllUsers ({ commit }) {
      const users = await api.getAllUsers()
      commit('SET_ALL_USERS', users.data)
      return users.data
    },

    async setNewAOTM ({ commit }, aotmObject) {
      const newAotm = await api.setNewAOTM(aotmObject)
      commit('SET_NEW_AOTM', newAotm.data)
      const users = await api.getAllUsers()
      commit('SET_ALL_USERS', users.data)
      return newAotm.data
    }
  }
})

export {
  createCuratorStore
}
