import * as config from '../config'

const createMainRoutes = ({ getCurrentUserHandler }) => {
  const protectRoute = async (to, _from, next) => {
    const currentUser = await getCurrentUserHandler()

    if (!currentUser) {
      next({
        name: 'login',
        query: {
          redirect: to.path
        }
      })
    } else {
      next()
    }
  }

  return [
    {
      path: '',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: 'register',
      name: 'register',
      component: () => import('@/views/Register.vue')
    },
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/Login.vue')
    },
    {
      path: 'loginsuccess',
      name: 'loginsuccess',
      component: () => import('@/views/FacebookLoginSuccess.vue')
    },
    {
      path: 'loginerror',
      name: 'loginerror',
      component: () => import('@/views/FacebookLoginError.vue')
    },
    {
      path: 'forgotten',
      name: 'forgotten',
      component: () => import('@/views/ForgottenPassword.vue')
    },
    {
      path: 'new-article',
      name: 'new-article',
      props: route => ({ query: route.params.draft }),
      component: () => import('@/views/ArticleComposer.vue'),
      beforeEnter: protectRoute
    },
    {
      path: 'articles',
      name: 'articles',
      component: () => import('@/views/Articles.vue')
    },
    {
      path: 'article/:slug',
      name: 'article',
      component: () => import('@/views/Article.vue')
    },
    ...(config.app.features.magazine ? [{
      path: 'magazine',
      name: 'magazine',
      component: () => import('@/views/Magazine.vue')
    }] : []),
    // TODO: This page seems quite outdated - discuss
    // {
    //   path: 'about',
    //   name: 'about',
    //   component: () => import('@/views/About.vue')
    // },
    {
      path: 'contact',
      name: 'contact',
      component: () => import('@/views/Contact.vue')
    },
    {
      path: 'profile/:id',
      component: () => import('@/views/Profile.vue'),
      children: [
        {
          path: '/',
          name: 'profile',
          component: () => import('@/components/user/profile/ProfilePosts.vue')
        },
        {
          path: 'profile-likes',
          name: 'profile-likes',
          component: () => import('@/components/user/profile/Likes.vue')
        },
        {
          path: 'following',
          name: 'profile-following',
          component: () => import('@/components/user/profile/Following.vue')
        },
        {
          path: 'followers',
          name: 'profile-followers',
          component: () => import('@/components/user/profile/Followers.vue')
        }
      ]
    },
    {
      path: 'settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue')
    },
    {
      path: 'feed',
      name: 'feed',
      component: () => import('@/views/MyFeed.vue'),
      beforeEnter: protectRoute
    },
    {
      path: 'drafts',
      name: 'drafts',
      component: () => import('@/views/Drafts.vue'),
      beforeEnter: protectRoute
    },
    {
      path: 'submissions',
      name: 'submissions',
      component: () => import('@/views/Submissions.vue'),
      beforeEnter: protectRoute
    },
    {
      path: 'tags/:tag',
      name: 'tags',
      component: () => import('@/views/Tags.vue')
    },
    {
      path: 'privacy-policy',
      name: 'privacy',
      component: () => import('@/views/Privacy.vue')
    },
    {
      path: 'cookie-policy',
      name: 'cookies',
      component: () => import('@/views/Cookies.vue')
    },
    {
      path: 'community-guidelines',
      name: 'community-guidelines',
      component: () => import('@/views/CommunityGuidelines.vue')
    },
    // TODO: This page is out of date and needs amending
    // {
    //   path: '/podcast',
    //   name: 'podcast',
    //   component: () => import('@/views/Podcast.vue')
    // },
    {
      path: 'unsubscribe/:userId/:authorId',
      name: 'unsubscribe-follow',
      component: () => import('@/views/UnsubscribeFollowEmails.vue'),
      beforeEnter: protectRoute
    },
    {
      path: 'page-not-found',
      name: 'page-not-found',
      alias: '*',
      component: () => import('@/views/PageNotFound.vue')
    }
  ]
}

export {
  createMainRoutes
}
