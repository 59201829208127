export default {
  namespaced: true,
  state: {
    survey: {}
  },
  getters: {
    survey: state => state.survey
  },
  mutations: {
    SET_SURVEY (state, survey) {
      state.survey = survey
    }
  },
  actions: {
    async getSurvey ({ commit }) {
      const sub = await this.$api.getSurvey()
      commit('SET_SURVEY', sub.data)
      return sub.data
    },

    async sendVote (blah, voteObj) {
      const sub = await this.$api.sendSurveyVote(voteObj)
      return sub.data
    }
  }
}
