const { createArticlesStore } = require('./articles')
const { createCuratorStore } = require('./curator')
const { createSubmissionsStore } = require('./submissions')
const { createSurveysStore } = require('./surveys')

const createCurateStore = ({
  authStorage,
  api
}) => ({
  namespaced: true,

  modules: {
    articles: createArticlesStore({
      api
    }),
    curator: createCuratorStore({
      authStorage,
      api
    }),
    submissions: createSubmissionsStore({
      api
    }),
    surveys: createSurveysStore({
      api
    })
  }
})

export {
  createCurateStore
}
