
const createArticlesStore = ({ api }) => ({
  namespaced: true,

  state: {
    articles: [],
    lastImageUpload: null,
    lastArchived: null,
    current: {}
  },
  getters: {
    articles: state => {
      return state.articles
    },
    current: state => state.current
  },
  mutations: {
    SET_ARTICLES (state, articles) {
      state.articles = articles || []
    },
    LAST_IMAGE_UPLOAD (state, url) {
      state.lastImageUpload = url
    },
    SET_LAST_ARCHIVED (state, articleId) {
      state.lastArchived = articleId
    },
    SET_CURRENT (state, current = {}) {
      state.current = current
    }
  },
  actions: {
    async getPublishedArticles ({ commit }, args) {
      const articles = await api.getPublishedArticles(args.offset, args.limit)
      commit('SET_ARTICLES', articles.data)
      return articles.data
    },

    async updateImage ({ commit }, formData) {
      const { data: url } = await api.updateCurationArticleImage(formData)
      commit('LAST_IMAGE_UPLOAD', url.imageUrl)
      return url
    },

    async archiveAnArticle ({ commit }, publishObj) {
      const { data: published } = await api.archiveAnArticle(publishObj)
      commit('SET_LAST_ARCHIVED', publishObj.id)
      return published
    },

    async getArticleById ({ commit }, articleObj) {
      // TODO: Remove this global $api call
      //  TODO: Probable should have a redundant `getArticle` in curation api vs mixing concerns
      const article = await api.getArticleBySlug(articleObj.slug)
      commit('SET_CURRENT', article.data[0])
      return article.data[0]
    },

    async updateAnArticle ({ commit }, article) {
      const { data: anArticle } = await api.upsertArticle({ ...article })
      commit('SET_CURRENT', anArticle)
      return anArticle
    },

    async postArticleImage ({ commit }, formData) {
      const { data: url } = await api.uploadAnImage(formData)
      commit('LAST_IMAGE_UPLOAD', url.imageUrl)
      return url
    },

    async removeTagsFromArticle ({ commit }, articleObj) {
      const article = await api.deleteTagsFromArticle(articleObj.articleId, articleObj.tags)
      commit('SET_CURRENT', article.data)
      return commit
    }
  }
})

export {
  createArticlesStore
}
