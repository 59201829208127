
const createSurveysStore = ({ api }) => ({
  namespaced: true,
  state: {
    surveyResults: {}
  },
  getters: {
    getSurveyResults: state => state.surveyResults
  },
  mutations: {
    SET_RESULTS (state, results) {
      state.surveyResults = results
    }
  },
  actions: {
    async createSurvey (_, surveyObj) {
      return await api.newSurvey(surveyObj)
    },

    async getAllSurveys () {
      const subs = await api.getAllSurveys()
      return subs.data.reverse()
    },

    async publishSurvey (_, surveyId) {
      return await api.publishSurvey(surveyId)
    },

    async updateImage (_, formData) {
      const { data: url } = await api.updateSurveyImage(formData)
      return url
    },

    async retrieveSurveyResults ({ commit }, survey) {
      const { data: results } = await api.getSurveyResults(survey)
      commit('SET_RESULTS', results)
    }
  }
})

export {
  createSurveysStore
}
