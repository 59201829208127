import { createAxiosClient } from './client'
import { captureServerResponseErrorInterceptor } from './interceptors'

// Only captures server response errors
const createBasicClient = ({ errorService }) => (config) => createAxiosClient({
  ...config,
  responseErrorInterceptors: [
    captureServerResponseErrorInterceptor({ errorService })
  ]
}).client

export { createBasicClient }
