const createSubmissionsStore = ({ api }) => ({
  namespaced: true,

  state: {
    submissions: [],
    requests: [],
    current: {},
    error: null,
    lastImageUpload: null,
    lastPublished: '',
    rejectionReasons: [],
    lastRejected: '',
    rejectionInputs: [],
    lastDeleted: []
  },
  getters: {
    getAllDraftSubmissions: state => {
      return state.submissions
    },
    submissions: state => {
      const submissions = state.submissions = state.submissions.filter(submission => {
        if (!submission.draft) {
          return submission
        }
        if (submission.Statuses.length > 0) {
          return submission
        }
        if (submission.RejectionReasons.length > 0) {
          return submission
        }
      })
      return submissions
    },
    current: state => state.current,
    error: state => state.error,
    rejectionReasons: state => state.rejectionReasons,
    rejectionInputs: state => state.rejectionInputs
  },
  mutations: {
    SET_SUBMISSIONS (state, submissions) {
      state.submissions = submissions || []
    },
    SET_CURRENT (state, current = {}) {
      state.current = current
    },
    SET_REQUESTED (state, request) {
      state.requests.push(request)
    },
    SET_ERROR (state, error) {
      state.error = error
    },
    LAST_IMAGE_UPLOAD (state, url) {
      state.lastImageUpload = url
    },
    SET_LAST_PUBLISHED (state, id) {
      state.lastPublished = id
    },
    SET_REJECTION_REASONS (state, reasons) {
      state.rejectionReasons = reasons
    },
    SET_LAST_REJECTED (state, submissionId) {
      state.lastReject = submissionId
    },
    SET_REJECTION_INPUTS (state, inputsObj) {
      state.rejectionInputs = inputsObj
    },
    SET_LAST_DELETED (state, submissionsArray) {
      state.lastDeleted = submissionsArray
    }
  },
  actions: {
    async createNewSubmission ({ commit }, subObj) {
      subObj = {
        ...subObj,
        typeId: 1
      }
      await api.createSubmission({ ...subObj })
      return commit
    },
    async createOrUpdateDraft ({ commit }, submission) {
      try {
        const { data: submitted } = await api.upsertSubmission({ ...submission })
        commit('SET_CURRENT', submitted)
        return submitted
      } catch (err) {
        commit('SET_ERROR', err)
        throw err
      }
    },
    async getAllSubs ({ commit }) {
      const subs = await api.getAllCurateSubmissions()
      commit('SET_SUBMISSIONS', subs.data)
      return subs.data
    },

    async deleteSub ({ commit }, subId) {
      await api.deleteASubmission(subId)
      return commit
    },

    async editSub ({ commit }, subObj) {
      await api.editASubmission({ ...subObj })
      return commit
    },

    async getSubById ({ commit }, subObj) {
      const sub = await api.getASubmission(subObj.id)
      commit('SET_CURRENT', sub.data)
      return sub.data
    },

    async addTagsSubmission ({ commit }, subObj) {
      await api.addTagsToSubmission(subObj.submissionId, subObj.tags)
      return commit
    },

    async removeTagsFromSubmission ({ commit }, subObj) {
      const sub = await api.deleteTagsFromSubmission(subObj.submissionId, subObj.tags)
      commit('SET_CURRENT', sub.data)
      return commit
    },

    async postArticleImage ({ commit }, formData) {
      const { data: url } = await api.uploadCurateImage(formData)
      commit('LAST_IMAGE_UPLOAD', url.imageUrl)
      return url
    },

    async publishArticle ({ commit }, subObj) {
      await api.publishArticle(subObj.id)
      commit('SET_LAST_PUBLISHED', subObj.id)
    },

    async getRejectionReasons ({ commit }) {
      const { data: reasons } = await api.getRejectionReasons()
      commit('SET_REJECTION_REASONS', reasons)
    },

    async rejectArticle ({ commit }, subObj) {
      await api.rejectArticle(subObj)
      commit('SET_LAST_REJECTED', subObj.id)
    },

    async setRejectionInputs ({ commit }, inputsObj) {
      commit('SET_REJECTION_INPUTS', inputsObj)
    },

    async deleteSubmissions ({ commit }, submissionsArray) {
      await api.deleteSubmissions(submissionsArray)
      commit('SET_LAST_DELETED', submissionsArray)
    }
  }
})

export {
  createSubmissionsStore
}
