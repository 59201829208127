
const DEFAULT_ACCESS_TOKEN_KEY = 'token'
const DEFAULT_REFRESH_TOKEN_KEY = 'refresh'
const DEFAULT_USER_KEY = 'user'

const createAuthStorage = (storage, {
  accessTokenKey = DEFAULT_ACCESS_TOKEN_KEY,
  refreshTokenKey = DEFAULT_REFRESH_TOKEN_KEY,
  userTokenKey = DEFAULT_USER_KEY
} = {}) => {
  const getAccessToken = () => storage.getItem(accessTokenKey)
  const setAccessToken = (token) => storage.setItem(accessTokenKey, token)

  const getRefreshToken = () => storage.getItem(refreshTokenKey)
  const setRefreshToken = (refreshToken) => storage.setItem(refreshTokenKey, refreshToken)

  const setTokens = ({ token, refreshToken }) => {
    setAccessToken(token)
    setRefreshToken(refreshToken)
  }

  const getUser = () => JSON.parse(storage.getItem(userTokenKey))
  const setUser = (user) => storage.setItem(userTokenKey, JSON.stringify(user))

  const clear = () => {
    [accessTokenKey, refreshTokenKey, userTokenKey].map((key) => storage.removeItem(key))
  }

  return {
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
    setTokens,
    getUser,
    setUser,
    clear
  }
}

// TODO: Move definition out of this module to avoid test reliance
// on global localStorage
const authStorage = createAuthStorage(localStorage, {
  accessTokenKey: 'token',
  refreshTokenKey: 'refresh',
  userTokenKey: 'user'
})
const curateAuthStorage = createAuthStorage(localStorage, {
  accessTokenKey: 'curateToken',
  refreshTokenKey: 'curateRefresh',
  userTokenKey: 'curateUser'
})

export { authStorage, createAuthStorage, curateAuthStorage }
