import { z } from 'zod'
import { env } from './env'

const AppConfigSchema = z.object({
  logo: z.object({
    IMG: z.string(),
    WIDTH: z.number(),
    HEIGHT: z.number()
  }),
  STRAPLINE: z.string(),
  ABBREVIATED_NAME: z.string(),
  CONTACT_EMAIL: z.string().email(),
  GDPR_EMAIL: z.string().email(),
  meta: z.object({
    HOME_TITLE: z.string(),
    GENERIC_DESCRIPTION: z.string().max(150)
  }),
  social: z.object({
    TWITTER_USERNAME: z.string().optional(),
    FACEBOOK_PAGE: z.string().optional(),
    LINKEDIN_COMPANY: z.string().optional(),
    INSTAGRAM_USERNAME: z.string().optional(),
    SHOW_FACEBOOK_AUTH: z.boolean()
  }),
  features: z.object({
    podcast: z.object({
      TITLE: z.string(),
      PODBEAN_IFRAME_URL: z.string().url()
    }).optional(),
    magazine: z.object({
      ISSUU_USERNAME: z.string()
    }).optional()
  }),
  legal: z.object({
    regulatoryBody: z.string(),
    profession: z.string(),
    field: z.string(),
    sector: z.string()
  })
})

const ConfigSchema = z.object({
  swn: AppConfigSchema,
  epn: AppConfigSchema
})

const appConfigs = {
  swn: {
    logo: {
      IMG: '/img/logo-swn.svg',
      WIDTH: 800,
      HEIGHT: 54
    },
    STRAPLINE: 'The magazine for social workers, by social workers',
    ABBREVIATED_NAME: 'SWN',
    CONTACT_EMAIL: 'press@mysocialworknews.com',
    GDPR_EMAIL: 'gdpr@mysocialworknews.com',
    meta: {
      HOME_TITLE: 'Social Work News | The Magazine for Social Workers, by Social Workers',
      GENERIC_DESCRIPTION: 'A magazine for social workers, by social workers - dedicated to uniting social workers across the globe and giving them a voice.'
    },
    social: {
      TWITTER_USERNAME: 'myswnews',
      FACEBOOK_PAGE: 'mysocialworknews',
      LINKEDIN_COMPANY: 'social-work-news-magazine',
      INSTAGRAM_USERNAME: 'myswnews',
      SHOW_FACEBOOK_AUTH: true
    },
    features: {
      podcast: {
        TITLE: 'Social Work Radio',
        PODBEAN_IFRAME_URL: 'https://www.podbean.com/player-v2/?i=6tg4f-43eb28-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=f2f2f7&font-color=auto&logo_link=episode_page&order=episodic&limit=1&filter=all&ss=8589a91146abd9493c961e6dc77d840d&btn-skin=c73a3a&size=160'
      },
      magazine: {
        ISSUU_USERNAME: 'socialworknews'
      }
    },
    legal: {
      regulatoryBody: 'Social Work England',
      profession: 'social worker',
      field: 'social work',
      sector: 'social care'
    }
  },

  epn: {
    logo: {
      IMG: '/img/logo-epn.svg',
      WIDTH: 644,
      HEIGHT: 54
    },
    STRAPLINE: 'The magazine for social workers, by social workers',
    ABBREVIATED_NAME: 'Ed Psych',
    CONTACT_EMAIL: 'press@edpsychnews.com',
    GDPR_EMAIL: 'gdpr@edpsychnews.com',
    meta: {
      HOME_TITLE: 'Ed Psych News',
      GENERIC_DESCRIPTION: ''
    },
    social: {
      TWITTER_USERNAME: 'edpsych_news',
      FACEBOOK_PAGE: 'edpsychnews',
      LINKEDIN_COMPANY: 'ed-psych-news',
      INSTAGRAM_USERNAME: 'edpsychnews',
      SHOW_FACEBOOK_AUTH: true
    },
    features: {},
    legal: {
      regulatoryBody: 'The Health and Care Professions Council (HCPC)',
      profession: 'educational psychologist',
      field: 'educational psychology',
      sector: 'psychology'
    }
  }
}

export const app = ConfigSchema.parse(appConfigs)[env.VUE_APP_CONFIG_KEY]
