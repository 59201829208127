import { eventService } from '@/services/event'

const createAuthEventService = (eventService, {
  sessionExpiredEventKey
} = {}) => ({
  sessionExpired: () => {
    eventService.emit(sessionExpiredEventKey, { expired: true })
  },
  onSessionExpired: (callback) => {
    eventService.on(sessionExpiredEventKey, callback)
  },
  off: () => {
    eventService.off(sessionExpiredEventKey, () => {})
  }
})

const authEventService = createAuthEventService(eventService, {
  sessionExpiredEventKey: 'sessionDidExpire'
})

// TODO: Maybe shouldn't share same event service, but is probably ok
const curateAuthEventService = createAuthEventService(eventService, {
  sessionExpiredEventKey: 'curateSessionDidExpire'
})

export {
  authEventService,
  curateAuthEventService
}
