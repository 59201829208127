
import { createBasicClient, createWithSessionClient } from './client'
import { api, utilityAPI, authAPI } from './apis'

const API_URL = process.env.VUE_APP_API_URL

const createApiService = ({ errorService, authStorage, authEventService }) => {
  const unauthenticatedClient = createBasicClient({ errorService })({
    baseURL: API_URL
  })

  const authClient = authAPI(unauthenticatedClient)
  const utilityClient = utilityAPI(unauthenticatedClient)

  const withSessionClient = createWithSessionClient({
    errorService,
    authStorage,
    authEventService,
    refreshTokensHandler: authClient.refreshTokens
  })({
    baseURL: API_URL
  })

  const apiClient = api(withSessionClient)

  return {
    ...utilityClient,
    ...authClient,
    ...apiClient,

    // TODO: Login API should really return the user object to prevent this
    // coupling between the authed client and unauthenticated client
    loginWithEmailAndPassword: async (email, password) => {
      const tokens = await authClient.login({ email, password })
      authStorage.setTokens(tokens)
      const user = await apiClient.getCurrentUser()
      return {
        ...tokens,
        user
      }
    }
  }
}

export {
  createApiService
}
