import VueRouter from 'vue-router'

const createRouter = (Vue, {
  mainRoutes,
  curateRoutes
}) => {
  Vue.use(VueRouter)

  const router = new VueRouter({
    mode: 'history',
    routes: [
      {
        path: '/curate',
        component: () => import('@/components/curate/CurateLayout.vue'),
        children: curateRoutes
      },
      {
        path: '/',
        component: () => import('@/components/MainLayout.vue'),
        children: mainRoutes
      }
    ],
    scrollBehavior (to, _from, savedPosition) {
      if (savedPosition) {
        return { ...savedPosition, behavior: 'smooth' }
      } else {
        if (to.hash) {
          Vue.nextTick(() => {
            const element = document.getElementById(to.hash.slice(1))
            if (element) {
              return element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          })
        }
        return { x: 0, y: 0 }
      }
    }
  })

  return router
}

export {
  createRouter
}
