export default {
  namespaced: true,

  state: {
    tags: [],
    latestTagsFetched: [],
    parentTags: []
  },
  getters: {
    getAllTags: state => {
      return state.tags
    },
    tags: state => state.tags,
    parentTags: state => state.parentTags
  },
  mutations: {
    SET_TAGS (state, tags) {
      state.tags = tags || []
    },
    ADD_NEW_TAG (state, tag) {
      state.tags.push(tag)
    },
    SET_LATEST_TAGS_FETCHED (state, latestTagsFetched) {
      state.latestTagsFetched = latestTagsFetched || []
    },
    SET_PARENT_TAGS (state, parentTags) {
      state.parentTags = parentTags || []
    }
  },
  actions: {
    async getTags ({ commit }) {
      const tags = await this.$api.getAllTags()
      commit('SET_TAGS', tags.data)
      return tags.data
    },

    async createNewTag ({ commit }, tagName) {
      const { data: tag } = await this.$api.createTag(tagName)
      commit('ADD_NEW_TAG', tag)
      return tag
    },

    async getArticleTags ({ commit }, articleId) {
      const tags = await this.$api.getArticleTags(articleId)
      commit('SET_LATEST_TAGS_FETCHED', tags.data)
      return tags
    },

    async getParentTags ({ commit }) {
      const tags = await this.$api.getParentTags()
      commit('SET_PARENT_TAGS', tags.data)
      return tags
    }
  }
}
