export default {
  namespaced: true,

  state: {
    latestIssue: null
  },
  getters: {
    getLatestIssue: state => {
      return state.latestIssue
    }
  },
  mutations: {
    SET_LATEST_ISSUE (state, issue) {
      state.latestIssue = issue
    }
  },
  actions: {
    async fetchLatestIssue ({ commit }) {
      const response = await this.$api.getLatestIssue()
      const issue = response.data
      if (issue) {
        commit('SET_LATEST_ISSUE', issue)
      }
      return issue
    }
  }
}
