
const createUnauthorizedResponseErrorInterceptor = (handler) => async (error, context) => {
  const { response } = error
  if (!response) throw error

  const { status } = response

  if (status === 401) return handler(error, context)
  throw error
}

const refreshAndRetryResponseErrorInterceptor = ({
  authStorage,
  refreshTokensHandler
}) => createUnauthorizedResponseErrorInterceptor(
  async (originalError, context) => {
    const isRetryAttempt = context.config?._retry
    if (isRetryAttempt) throw originalError

    const refreshToken = authStorage.getRefreshToken()
    if (!refreshToken) throw originalError

    const response = await refreshTokensHandler(refreshToken)
    if (!response.success) throw originalError

    authStorage.setTokens(response.data)

    return context.axios({
      ...originalError.config,
      _retry: true
    })
  }
)

const expireSessionResponseErrorInterceptor = ({
  authStorage,
  authEventService
}) => createUnauthorizedResponseErrorInterceptor(
  async (originalError) => {
    authStorage.clear()
    authEventService.sessionExpired()
    throw originalError
  }
)

export {
  refreshAndRetryResponseErrorInterceptor,
  expireSessionResponseErrorInterceptor
}
