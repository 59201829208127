import z from 'zod'

const AxiosResponseSchema = (DataSchema) => z.object({
  data: DataSchema
}).transform((axiosResponse) => axiosResponse.data)

const RegisterNewUserResponseSchema = z.object({
  id: z.string()
})

const TokenResponseSchema = z.object({
  token: z.string(),
  refreshToken: z.string()
})

const authAPI = (client) => ({
  registerNewUser: async (user) => {
    const response = await client.post('/register', { ...user })
    return AxiosResponseSchema(RegisterNewUserResponseSchema).parse(response)
  },

  login: async ({ email, password }) => {
    const response = await client.post('/login', { email, password })
    const maybeData = AxiosResponseSchema(TokenResponseSchema).safeParse(response)
    if (!maybeData.success) throw new Error('We couldn\'t find a user with that email/password combo.')
    return maybeData.data
  },

  refreshTokens: async (refreshToken) => {
    const response = await client.post('/auth/refresh-tokens', { refreshToken })
    return AxiosResponseSchema(TokenResponseSchema).safeParse(response)
  },

  // TODO: Name this better - ResetPassword
  requestUserReset: async (email) => {
    client.post('/users/reset', email)
  }
})

export {
  authAPI
}
