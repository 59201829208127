export default {
  namespaced: true,

  state: {
    hasUnsaved: false
  },
  getters: {
    hasUnsaved: state => state.hasUnsaved
  },
  mutations: {
    SET_HAS_UNSAVED (state, hasUnsaved) {
      state.hasUnsaved = hasUnsaved
    }
  },
  actions: {
    setHasUnsaved ({ commit }, payload) {
      commit('SET_HAS_UNSAVED', payload)
    }
  }
}
